import React from 'react';
import ZoomMtgEmbedded from '@zoomus/websdk/embedded';

import { Layout } from '../components';
import { getLessonAuthToken } from '@src/utils/mmc-api/api';
import { useQuery } from '@tanstack/react-query';

export default function LessonPage() {
  const [client, setClient] = React.useState();
  const [joined, setJoined] = React.useState(false);

  const { refetch: joinMeeting, isLoading } = useQuery(
    ['get-auth-token'],
    () => {
      if (!password) throw 'No password';
      setIsJoining(true);
      return getLessonAuthToken();
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: ({ signature }) => {
        startMeeting(signature);
        setJoined(true);
      },
    },
  );

  const [isJoining, setIsJoining] = React.useState(false);
  const [password, setPassword] = React.useState('');

  React.useEffect(() => {
    const zoomClient = ZoomMtgEmbedded.createClient();
    setClient(zoomClient);
  }, []);

  async function startMeeting(signature) {
    let meetingSDKElement = document.getElementById('meetingSDKElement');

    let inited = true;
    await client
      .init({
        zoomAppRoot: meetingSDKElement,
        language: 'en-US',
        patchJsMedia: true,
        customize: {
          //   video: {
          //     popper: {
          //       disableDraggable: true,
          //     },
          //     viewSizes: {
          //       default: {
          //         width: 1000,
          //         height: 600,
          //       },
          //       ribbon: {
          //         width: 1000,
          //         height: 700,
          //       },
          //     },
          //   },
        },
      })
      .catch(() => (inited = false));

    if (!inited) return;
    await client
      .join({
        signature: signature,
        sdkKey: 'j5iq9TJKSDCn7FIu6qzStQ',
        meetingNumber: '5452741447',
        password,
        userName: 'Student',
        error: (e) => {
          console.log('Error occured');
          console.log(e);
        },
      })
      .then(() => {
        setPassword('');
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Layout>
      <div className="container content-section">
        <h1>Online lesson</h1>

        {/* For Component View */}
        <div id="meetingSDKElement" style={{ height: !isJoining ? '0' : '40rem' }}>
          {/* Zoom Meeting SDK Component View Rendered Here */}
        </div>

        {!joined && (
          <>
            <label htmlFor="password">Meeting password</label>
            <input
              type="text"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button className="button" onClick={joinMeeting}>
              Join Meeting
            </button>
          </>
        )}
      </div>
    </Layout>
  );
}
